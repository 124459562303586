<script>
  /**
   * An ordered or unordered list of text items (`BaseListItem` components).
   */
  export default {
    inject: {
      richText: {
        from: 'BaseRichText',
        default: undefined,
      },
    },
    provide() {
      return {
        BaseList: this,
      }
    },
    props: {
      /** The type of list, which affects the component’s semantics and the marker on each item. */
      type: {
        type: String,
        required: true,
        validator: (value) => ['decimal', 'disc', 'check'].includes(value),
      },
      /** The size of the gap between list items. */
      gapSize: {
        type: String,
        default: 'sm',
        validator: (value) => ['none', 'sm', 'md', 'lg'].includes(value),
      },
      /** The list’s font size. Defaults to the font size of the `BaseRichText` component this list is in, if any. Otherwise, defaults to `base`. */
      fontSize: {
        type: String,
        default: undefined,
        validator: (value) => ['3xs', '2xs', 'xs', 'sm', 'base', 'lg', 'xl'].includes(value),
      },
      /** The list’s leading. Defaults to the leading of the `BaseRichText` component this list is in, if any. Otherwise, defaults to `snug`. */
      leading: {
        type: String,
        default: undefined,
        validator: (value) => ['tight', 'snug', 'normal', 'close'].includes(value),
      },
    },
    computed: {
      component() {
        switch (this.type) {
          case 'decimal':
            return 'ol'
          default:
            return 'ul'
        }
      },
      listStyleClass() {
        switch (this.type) {
          case 'decimal':
            return 'list-decimal'
          case 'disc':
            return 'list-disc'
          default:
            return ''
        }
      },
      finalFontSize() {
        if (this.fontSize) {
          return this.fontSize
        }
        if (this.richText) {
          return this.richText.fontSize
        }
        return 'base'
      },
      finalLeading() {
        if (this.leading) {
          return this.leading
        }
        if (this.richText) {
          return this.richText.leading
        }
        return 'snug'
      },
    },
  }
</script>

<template>
  <!-- for purgecss: list--gap-none list--gap-sm list--gap-md list--gap-lg -->
  <!-- for purgecss: text-3xs text-2xs text-xs text-sm text-base text-lg text-xl -->
  <!-- for purgecss: leading-tight leading-snug leading-normal leading-close -->
  <component
    :is="component"
    :class="`list list--gap-${gapSize} ${listStyleClass} text-${finalFontSize} text-left leading-${finalLeading}`"
  >
    <!-- @slot The list items. Should contain one or multiple `BaseListItem` components. -->
    <slot />
  </component>
</template>

<style lang="postcss" scoped>
  .list {
    padding-left: 1.75em;

    &--gap-sm > * + * {
      margin-top: 0.25em;
    }

    &--gap-md > * + * {
      margin-top: 0.5em;
    }

    &--gap-lg > * + * {
      margin-top: 1em;
    }
  }
</style>
